import React from 'react';
import { Box } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

const Loading = (props) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      height="70%"
      justifyContent="center"
      style={{ alignItems: 'center' }}
    >
      <CircularProgress size="8rem" disableShrink />
    </Box>
  );
};

export default Loading;
