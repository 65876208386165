import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';


//Layout
const AuthLayout = lazy(() => import('layouts/AuthLayout'));
const ProjectLayout = lazy(() => import('layouts/ProjectLayout'));
const SubjectLayout = lazy(() => import('layouts/SubjectLayout'));
const DashboardLayout = lazy(() => import('layouts/DashboardLayout'));

//common
const NotFoundView = lazy(() => import('views/errors/NotFoundView'));
const SocketTest = lazy(() => import('views/errors/SocketTest'));

//Auth View
const Login = lazy(() => import('views/auth/Login'));
const Join = lazy(() => import('views/auth/Join'));

//Page View
const ProjectList = lazy(() => import('views/main/Project'));
const SubjectList = lazy(() => import('views/main/Subject'));
const Dashboard = lazy(() => import('views/main/Dashboard'));

const UploadPage = lazy(() => import('views/Import'));




const routes = [
  {
    path: '/test',
    element: <UploadPage />,
    children: [
      { path: '', element: <UploadPage /> },
      { path: '*', element: <Navigate to="/404" /> },
    ],
  },
  {
    path: '/auth',
    element: <AuthLayout />,
    children: [
      { path: 'login', element: <Login /> },
      { path: 'join', element: <Join /> },
      // { path: 'test', element: <WebSocketTest /> },
      { path: '*', element: <Navigate to="/404" /> },
    ],
  },
  {
    path: '/project',
    element: <ProjectLayout />,
    children: [
      { path: '', element: <ProjectList /> },
      { path: '*', element: <Navigate to="/404" /> },
    ],
  },
  {
    path: '/project/:projectId',
    element: <SubjectLayout />,
    children: [
      { path: '/subject', element: <SubjectList /> },
      { path: '*', element: <Navigate to="/404" /> },
    ],
  },
  {
    path: '/subject/:subjectId',
    element: <DashboardLayout />,
    children: [
      { path: '/dashboard', element: <Dashboard /> },
      { path: '*', element: <Navigate to="/404" /> },
    ],
  },
  {
    path: '/',
    children: [
      { path: '/', element: <Navigate to="/auth/login" /> },
      { path: 'test', element: <SocketTest /> },
      { path: '404', element: <NotFoundView /> },
      { path: '*', element: <Navigate to="/404" /> },
    ],
  },
];

export default routes;
